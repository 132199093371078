"use client";

import { openScheduleAVisitModal } from "@/components/schedule-a-visit";
import { ArrowRight } from "lucide-react";

const CTA = () => {
  return (
    <div className="overflow-hidden max-w-screen-xl mx-auto relative flex flex-col items-center mt-20 max-md:mb-8 md:mt-24 gap-6 md:gap-8 py-16 md:pt-40 md:pb-24">
      <h2 className="text-2xl md:text-6xl text-primary-950 font-semibold text-center z-20">
        Still looking for your dream <br />
        property?
      </h2>
      <p className="text-xs md:text-base text-primary-700 text-center z-20">
        Let us know, and we’ll reach out with options tailored just for you!
        <br />
        Just drop your phone number below.
      </p>
      <button
        onClick={() => {
          openScheduleAVisitModal({ source: "homepage-cta-button" });
        }}
        className="w-fit rounded-full border border-primary p-2 md:p-4 flex items-center justify-center z-20 transition-all duration-300 hover:scale-105"
      >
        <ArrowRight className="w-4 h-4 md:w-6 md:h-6" />
      </button>
      <div className="bg-gradient-to-b from-transparent to-white absolute top-0 left-0 right-0 bottom-0 z-10" />
      <div className="absolute top-0 aspect-square w-[328px] md:w-[1240px] bg-[#2E6800] bg-opacity-5 rounded-t-full border border-white/5" />
      <div className="absolute top-[40px] md:top-[100px] aspect-square w-[275px] md:w-[1040px] bg-[#2E6800] bg-opacity-5 rounded-full border border-white/5" />
      <div className="absolute top-[80px] md:top-[200px] aspect-square w-[222px] md:w-[840px] bg-[#2E6800] bg-opacity-5 rounded-full border border-white/5" />
    </div>
  );
};

export default CTA;
