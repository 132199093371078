"use client";

import { sendGAEvent } from "@next/third-parties/google";
import EventEmitter from "events";
import dynamic from "next/dynamic";
import { usePostHog } from "posthog-js/react";
import { useEffect, useState } from "react";
import { authenticate } from "../authenticate";
import LoadingModal from "../loading-modal";

const ScheduleAVisitForm = dynamic(() => import("./form"), {
  ssr: false,
  loading: () => <LoadingModal />,
});

class ScheduleAVisitEventEmitter extends EventEmitter {
  static instance = new ScheduleAVisitEventEmitter();

  constructor() {
    super();
  }

  static getInstance() {
    return this.instance;
  }
}

type ScheduleAVisitProps = {
  projectId?: string;
  isResale?: boolean;
  source: string;
};

export const openScheduleAVisitModal = async ({
  projectId,
  isResale,
  source,
}: ScheduleAVisitProps) => {
  authenticate({ source: "schedule_a_visit" })
    .then(() => {
      ScheduleAVisitEventEmitter.getInstance().emit("schedule-a-visit", {
        projectId,
        isResale,
        source,
      });
    })
    .catch(() => {
      console.log("User cancelled");
    });
};

export default function ScheduleAVisitModal() {
  const [isOpen, setIsOpen] = useState(false);
  const [isResale, setIsResale] = useState<boolean>(false);
  const [projectId, setProjectId] = useState<string>();

  const posthog = usePostHog();

  useEffect(() => {
    ScheduleAVisitEventEmitter.getInstance().on(
      "schedule-a-visit",
      (data: ScheduleAVisitProps) => {
        setProjectId(data?.projectId);
        setIsResale(!!data?.isResale);
        setIsOpen(true);

        posthog.capture("schedule_a_visit_modal_opened", {
          source: data?.source,
        });
        sendGAEvent("event", "schedule_a_visit_modal_opened", {
          source: data?.source,
        });
      }
    );
  }, [posthog]);

  if (!isOpen) return null;

  return (
    <ScheduleAVisitForm
      projectId={projectId}
      isResale={isResale}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    />
  );
}
